<template>
  <div data-theme="treasurebox">
    <video
      loop
      muted
      autoplay
      class="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-z-0"
    >
      <source src="/videos/bg.webm" type="video/webm" />
    </video>
    <ClientOnly>
      <div class="tw-relative tw-z-10 tw-min-h-screen tw-flex tw-flex-col">
        <slot />
      </div>
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
const session = useSession();
const storage = useGlobalStorage();
onMounted(() => {
  session.clear();
  storage.clear();
});
</script>
